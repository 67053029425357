<template>
  <v-container></v-container>
</template>

<script>
import Vue from 'vue'
export default {    
    methods: {
        SignOut() {
            localStorage.clear();
            Vue.$toast.open({
                  message: 'Sie wurden abgemeldet',
                  type: 'success',
              });
            this.$router.push("/")
            location.reload()
        }
    },
    created() {
        this.SignOut()
    }
}
</script>

<style>

</style>